document.addEventListener('DOMContentLoaded', function () {
    var modalButtons = document.querySelectorAll('.pricing-button-modal');
    modalButtons.forEach(function (button) {
        var modal = document.getElementById('modal-'+button.id)
        var closeButton = modal.querySelector('.close.btn');

        button.addEventListener('click', function (event) {
            event.preventDefault();
            modal.style.display = "block";
            modal.ariaModal = "true"
            modal.role = "dialog";
            modal.ariaHidden = "false"
            modal.classList.add('show')
        });

        closeButton.addEventListener('click', function (event) {
            modal.style.display = "none";
            modal.ariaModal = "false"
            modal.ariaHidden = "true"
            modal.classList.remove('show')
        })
    });
});
